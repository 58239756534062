import React from 'react';
import IndexRoute from '../../routes/index.routes';

const Landing = () => {
    return (
        <div>
            <IndexRoute />
        </div>
    );
};

export default Landing;
