import * as types from '../action.types';

const initialState = {};

const getCountryReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_COUNTRY_SUCCESS:
            return response;
        case types.GET_COUNTRY_ERROR:
            return response;
        default:
            return state;
    }
};

const makePaymentReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.MAKE_PAYMENT_SUCCESS:
            return response;
        case types.MAKE_PAYMENT_ERROR:
            return response;
        default:
            return state;
    }
};

const clearPaymentStateReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_PAYMENT_STATE_SUCCESS:
            return response;
        case types.CLEAR_PAYMENT_STATE_ERROR:
            return response;
        default:
            return state;
    }
};

const getProductPricesReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_PRODUCT_PRICES_SUCCESS:
            return response;
        case types.GET_PRODUCT_PRICES_ERROR:
            return response;
        default:
            return state;
    }
};

const clearProductPricesReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_PRODUCT_PRICES_SUCCESS:
            return response;
        case types.CLEAR_PRODUCT_PRICES_ERROR:
            return response;
        default:
            return state;
    }
};

const paymentSummaryReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.PAYMENT_SUMMARY_SUCCESS:
            return response;
        case types.PAYMENT_SUMMARY_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    getCountryReducer,
    makePaymentReducer,
    clearPaymentStateReducer,
    getProductPricesReducer,
    clearProductPricesReducer,
    paymentSummaryReducer,
};
