import Cookie from 'js-cookie';
import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    authCheckService,
    forgotPasswordService,
    knowMoreService,
    resendEmailService,
    resetPasswordService,
    sendOTPService,
    signInOTPService,
    signinService,
    signoutService,
    signUpOTPService,
    signupService,
    socialAccountLinkService,
    socialAccountUnLinkService,
    verifySignupService,
} from '../services/auth.service';

function* signupSaga(payload) {
    const response = yield call(signupService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.SIGNUP_SUCCESS, response });
    } else {
        yield put({ type: types.SIGNUP_ERROR, response });
    }
}

function* signinSaga(payload) {
    const response = yield call(signinService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.SIGNIN_SUCCESS, response });

        if (response?.data?.auth?.token) {
            const originPage = sessionStorage.getItem('origin_page');
            if (originPage) {
                window.location.replace(originPage);
            }
        }
    } else {
        yield put({ type: types.SIGNIN_ERROR, response });
    }
}

function* authCheckSaga() {
    const response = yield call(authCheckService);

    if (response && response.data && !response.data.error) {
        yield put({ type: types.AUTHCHECK_SUCCESS, response });
    } else {
        yield put({ type: types.AUTHCHECK_ERROR, response });
        if (Cookie.get('token')) {
            Cookie.remove('token');
            window.location.replace('/');
        }
    }
}

function* signoutSaga() {
    const response = yield call(signoutService);
    if (response && response.signout) {
        yield put({ type: types.SIGNOUT_SUCCESS, response });
        window.location.replace('/');
    } else {
        yield put({ type: types.SIGNOUT_ERROR, response });
    }
}

function* forgotPasswordSaga(payload) {
    const response = yield call(forgotPasswordService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.FORGOT_PASSWORD_SUCCESS, response });
    } else {
        yield put({ type: types.FORGOT_PASSWORD_ERROR, response });
    }
}

function* resetPasswordSaga(payload) {
    const response = yield call(resetPasswordService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.RESET_PASSWORD_SUCCESS, response });
    } else {
        yield put({ type: types.RESET_PASSWORD_ERROR, response });
    }
}

function* socialAccountLinkSaga(payload) {
    const response = yield call(socialAccountLinkService, payload);
    if (response && !response.error) {
        yield put({ type: types.SOCIAL_ACCOUNT_LINK_SUCCESS, response });
        window.location.reload();
    } else {
        yield put({ type: types.SOCIAL_ACCOUNT_LINK_ERROR, response });
    }
}

function* socialAccountUnLinkSaga(payload) {
    const response = yield call(socialAccountUnLinkService, payload);
    if (response && !response.error) {
        yield put({ type: types.SOCIAL_ACCOUNT_UNLINK_SUCCESS, response });
        window.location.reload();
    } else {
        yield put({ type: types.SOCIAL_ACCOUNT_UNLINK_ERROR, response });
    }
}

function* verifySignupSaga(payload) {
    const response = yield call(verifySignupService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.VERIFY_SIGNUP_SUCCESS, response });
    } else {
        yield put({ type: types.VERIFY_SIGNUP_ERROR, response });
    }
}

function* sendOTPSaga(payload) {
    const response = yield call(sendOTPService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.SEND_OTP_SUCCESS, response });
    } else {
        yield put({ type: types.SEND_OTP_ERROR, response });
    }
}

function* signInOTPSaga(payload) {
    const response = yield call(signInOTPService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.SIGNIN_OTP_SUCCESS, response });
    } else {
        yield put({ type: types.SIGNIN_OTP_ERROR, response });
    }
}

function* signUpOTPSaga(payload) {
    const response = yield call(signUpOTPService, payload);
    if (response && response.data && !response.data.error) {
        yield put({ type: types.SIGNUP_OTP_SUCCESS, response });
    } else {
        yield put({ type: types.SIGNUP_OTP_ERROR, response });
    }
}

export {
    signupSaga,
    signinSaga,
    authCheckSaga,
    signoutSaga,
    forgotPasswordSaga,
    resetPasswordSaga,
    socialAccountLinkSaga,
    socialAccountUnLinkSaga,
    verifySignupSaga,
    sendOTPSaga,
    signInOTPSaga,
    signUpOTPSaga,
};
