import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const changePasswordService = (request) => {
    return axios
        .patch(`${REACT_APP_API_URL}/user/password/change`, request.data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const generatePasswordService = ({ data }) => {
    return axios
        .put(`${REACT_APP_API_URL}/user/password/create`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const changeEmailService = (request) => {
    return axios
        .patch(`${REACT_APP_API_URL}/user/email`, request.data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const userPurchaseService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/user/purchases`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const userSubscriptionService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/user/subscriptions`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const editProfileService = ({ data }) => {
    return axios
        .patch(`${REACT_APP_API_URL}/user`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    changePasswordService,
    generatePasswordService,
    changeEmailService,
    userPurchaseService,
    userSubscriptionService,
    editProfileService,
};
