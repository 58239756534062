import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const getPaymentKeysService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/payment/key`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const coursePriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const singleCoursePriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const relatedProductPriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const packagePriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const browsePackagePriceService = (request) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (request && request.data) {
        request &&
            request.data.map((obj, i) => {
                if (i === 0) {
                    url = url.concat(`${obj}`);
                } else {
                    url = url.concat(`,${obj}`);
                }
                return null;
            });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getLowestPriceService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/product/prices/lowest`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    getPaymentKeysService,
    coursePriceService,
    singleCoursePriceService,
    relatedProductPriceService,
    packagePriceService,
    browsePackagePriceService,
    getLowestPriceService,
};
