import { put, call } from 'redux-saga/effects';
import {
    homeIntroService,
    analysisIntroService,
    getWordService,
    homeBannerService,
} from '../services/home.service';

import * as types from '../action.types';

function* homeIntroSaga() {
    const response = yield call(homeIntroService);

    if (response && !response.error) {
        yield put({ type: types.HOME_INTRO_SUCCESS, response });
    } else {
        yield put({ type: types.HOME_INTRO_ERROR, response });
    }
}
function* analysisIntroSaga(payload) {
    const response = yield call(analysisIntroService, payload);

    if (response && !response.error) {
        yield put({ type: types.ANALYSIS_INTRO_SUCCESS, response });
    } else {
        yield put({ type: types.ANALYSIS_INTRO_ERROR, response });
    }
}

function* getWordSaga() {
    const response = yield call(getWordService);

    if (response && !response.error) {
        yield put({ type: types.GET_WORD_SUCCESS, response });
    } else {
        yield put({ type: types.GET_WORD_ERROR, response });
    }
}

function* homeBannerSaga() {
    const response = yield call(homeBannerService);

    if (response && !response.error) {
        yield put({ type: types.HOME_BANNER_SUCCESS, response });
    } else {
        yield put({ type: types.HOME_BANNER_ERROR, response });
    }
}

export { homeIntroSaga, analysisIntroSaga, getWordSaga, homeBannerSaga };
