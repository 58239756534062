import * as types from '../action.types';

const changePasswordAction = (data) => {
    return {
        type: types.CHANGE_PASSWORD,
        data,
    };
};

const generatePasswordAction = (data) => {
    return {
        type: types.GENERATE_PASSWORD,
        data,
    };
};

const changeEmailAction = (data) => {
    return {
        type: types.CHANGE_EMAIL,
        data,
    };
};

const userPurchaseAction = () => {
    return {
        type: types.USER_PURCHASE,
    };
};

const userSubscriptionAction = () => {
    return {
        type: types.USER_SUBSCRIPTION,
    };
};

const editProfileAction = (data) => {
    return {
        type: types.EDIT_PROFILE,
        data,
    };
};

export {
    changePasswordAction,
    generatePasswordAction,
    changeEmailAction,
    userPurchaseAction,
    userSubscriptionAction,
    editProfileAction,
};
