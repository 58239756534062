import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    courseListService,
    courseService,
    getCourseDetailsService,
    relatedCourseService,
    userCourseService,
} from '../services/course.service';

function* courseSaga(payload) {
    const response = yield call(courseService, payload);

    if (response && !response.error) {
        yield put({ type: types.COURSE_SUCCESS, response });
    } else {
        yield put({ type: types.COURSE_ERROR, response });
    }
}

function* getCourseDetailsSaga(payload) {
    const response = yield call(getCourseDetailsService, payload);

    if (response && !response.error) {
        yield put({ type: types.GET_COURSE_DETAILS_SUCCESS, response });
    } else {
        yield put({ type: types.GET_COURSE_DETAILS_ERROR, response });
    }
}

function* relatedCourseSaga(payload) {
    const response = yield call(relatedCourseService, payload);

    if (response && !response.error) {
        yield put({ type: types.RELATED_COURSE_SUCCESS, response });
    } else {
        yield put({ type: types.RELATED_COURSE_ERROR, response });
    }
}

function* courseListSaga(payload) {
    const response = yield call(courseListService, payload);

    if (response && !response.error) {
        yield put({ type: types.COURSE_LIST_SUCCESS, response });
    } else {
        yield put({ type: types.COURSE_LIST_ERROR, response });
    }
}

function* userCourseSaga() {
    const response = yield call(userCourseService);

    if (response && !response.error) {
        yield put({ type: types.USER_COURSE_SUCCESS, response });
    } else {
        yield put({ type: types.USER_COURSE_ERROR, response });
    }
}

export {
    courseSaga,
    getCourseDetailsSaga,
    relatedCourseSaga,
    courseListSaga,
    userCourseSaga,
};
