import * as types from '../action.types';

const initialState = {};

const couponListReducer = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case types.COUPON_LIST_SUCCESS:
            return response;
        case types.COUPON_LIST_ERROR:
            return response;
        default:
            return state;
    }
};

export { couponListReducer };
