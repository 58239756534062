import * as types from '../action.types';

const initialState = {};
const getPackageReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_PACKAGE_SUCCESS:
            return response;
        case types.GET_PACKAGE_ERROR:
            return response;
        default:
            return state;
    }
};

const getPackageDetailReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_PACKAGE_DETAIL_SUCCESS:
            return response;
        case types.GET_PACKAGE_DETAIL_ERROR:
            return response;
        default:
            return state;
    }
};

const getRelatedPackageReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_RELATED_PACKAGE_SUCCESS:
            return response;
        case types.GET_RELATED_PACKAGE_ERROR:
            return response;
        default:
            return state;
    }
};

const clearPackagePriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_PACKAGE_PRICE_SUCCESS:
            return response;
        case types.CLEAR_PACKAGE_PRICE_ERROR:
            return response;
        default:
            return state;
    }
};

const getTrialPackReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_TRIAL_PACK_SUCCESS:
            return response;
        case types.GET_TRIAL_PACK_ERROR:
            return response;
        default:
            return state;
    }
};

const userPackageReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.USER_PACKAGE_SUCCESS:
            return response;
        case types.USER_PACKAGE_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    getPackageReducer,
    getPackageDetailReducer,
    getRelatedPackageReducer,
    clearPackagePriceReducer,
    getTrialPackReducer,
    userPackageReducer,
};
