import * as types from '../action.types';

const initialState = {};
const mentorReducer = (state = initialState, action) => {
  const response = action.response;

  switch (action.type) {
    case types.GET_MENTOR_SUCCESS:
      return response;
    case types.GET_MENTOR_ERROR:
      return response;
    default:
      return state;
  }
};

export { mentorReducer };
