import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

export const getAllAccessService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/all-access`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export const getAllAccessPriceService = ({ data }) => {
    let url = `${REACT_APP_API_URL}/product/prices?p=`;
    if (data) {
        data?.map((obj, i) => {
            if (i === 0) {
                url = url.concat(`${obj}`);
            } else {
                url = url.concat(`,${obj}`);
            }
            return null;
        });
    }
    url = url.concat(`&normalize=true`);
    return axios
        .get(
            url,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};
