import * as types from '../action.types';

const initialState = {};
const homeIntroReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.HOME_INTRO_SUCCESS:
            return response;
        case types.HOME_INTRO_ERROR:
            return response;
        default:
            return state;
    }
};

const analysisIntroReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ANALYSIS_INTRO_SUCCESS:
            return response;
        case types.ANALYSIS_INTRO_ERROR:
            return response;
        default:
            return state;
    }
};

const getWordReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_WORD_SUCCESS:
            return response;
        case types.GET_WORD_ERROR:
            return response;
        default:
            return state;
    }
};

const homeBannerReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.HOME_BANNER_SUCCESS:
            return response;
        case types.HOME_BANNER_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    homeIntroReducer,
    analysisIntroReducer,
    getWordReducer,
    homeBannerReducer,
};
