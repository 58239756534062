import { put, call } from 'redux-saga/effects';
import { mentorService } from '../services/mentor.service';

import * as types from '../action.types';

function* mentorSaga(payload) {
  const response = yield call(mentorService, payload);

  if (response && !response.error) {
    yield put({ type: types.GET_MENTOR_SUCCESS, response });
  } else {
    yield put({ type: types.GET_MENTOR_ERROR, response });
  }
}

export { mentorSaga };
