import Cookie from 'js-cookie';
import axios from '../utils/axios.instance';
import { REACT_APP_API_URL } from './index.service';

const signupService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/signup`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const signinService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/login`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const socialSigninService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/social`, data)
        .then((response) => {
            Cookie.set('token', response?.data?.data?.auth?.token);
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const authCheckService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/user`, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const signoutService = () => {
    Cookie.remove('token');

    return {
        signout: true,
    };
    // return axios
    //     .get("/signout", {
    //         headers: {
    //             Authorization: `Bearer ${Cookie.get("token")}`
    //         }
    //     })
    //     .then(response => {
    //         return response.data;
    //     })
    //     .catch(err => {
    //         return err.response;
    //     });
};

const forgotPasswordService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/password/forgot`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const resetPasswordService = ({ data }) => {
    return axios
        .post(
            `${REACT_APP_API_URL}/auth/password/reset`,
            {
                newPassword: data?.newPassword,
            },
            {
                headers: {
                    Authorization: `Bearer ${data?.token}`,
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const socialAccountLinkService = ({ data }) => {
    return axios
        .put(`${REACT_APP_API_URL}/user/account/link`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const socialAccountUnLinkService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/user/account/unlink`, data, {
            headers: {
                Authorization: `Bearer ${Cookie.get('token')}`,
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const verifySignupService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/signup/verify`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const sendOTPService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/email/otp`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const signInOTPService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/login/otp`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

const signUpOTPService = ({ data }) => {
    return axios
        .post(`${REACT_APP_API_URL}/auth/signup/otp`, data)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response;
        });
};

export {
    signupService,
    signinService,
    authCheckService,
    signoutService,
    socialSigninService,
    forgotPasswordService,
    resetPasswordService,
    socialAccountLinkService,
    socialAccountUnLinkService,
    verifySignupService,
    sendOTPService,
    signInOTPService,
    signUpOTPService,
};
