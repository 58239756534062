import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const getPackageService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/package/list`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getPackageDetailService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/package/${data.packageId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getRelatedPackageService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/package/related?skip=${data.packageId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getTrialPackService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/package/free`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const userPackageService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/user/packages`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    getPackageService,
    getPackageDetailService,
    getRelatedPackageService,
    getTrialPackService,
    userPackageService,
};
