import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const homeIntroService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/home`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const analysisIntroService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/intro?kind=${data.type}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getWordService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/words`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const homeBannerService = () => {
    return axios
        .get(`${REACT_APP_API_URL}/ui/banner/list`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    homeIntroService,
    analysisIntroService,
    getWordService,
    homeBannerService,
};
