import Cookie from 'js-cookie';
import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { authCheckAction } from '../../store/actions/auth.action';
import { userSubscriptionAction } from '../../store/actions/profile.action';
import FallBackLoader from '../shared/components/loading-screen/FallBackLoader';
import PrivateRoute from './privateRoute/privateRoute';

const AllAccessDetail = lazy(() =>
    import('../modules/all-access/AllAccessDetail')
);
const Browse = lazy(() => import('../modules/browse/Browse'));

const Home = lazy(() => import('../modules/re-home/Home'));

const About = lazy(() => import('../layout/footer/pages/About'));
const ContactUs = lazy(() => import('../layout/footer/pages/ContactUs'));
const HelpFAQ = lazy(() => import('../layout/footer/pages/HelpFAQ'));
const PrivacyPolicy = lazy(() =>
    import('../layout/footer/pages/PrivacyPolicy')
);
const TermsConditions = lazy(() =>
    import('../layout/footer/pages/TermsConditions')
);
const AIAnalysis = lazy(() => import('../modules/ai-analysis/AIAnalysis'));
const AIAnalysisUpload = lazy(() =>
    import('../modules/analysis/components/ai-analysis/AIAnalysisUpload')
);
const ForgotPassword = lazy(() =>
    import('../modules/auth-v2/forgot-password/ForgotPassword')
);
const SignIn = lazy(() => import('../modules/auth-v2/sign-in/SignIn'));
const SignUp = lazy(() => import('../modules/auth-v2/sign-up/SignUp'));

const CourseDetails = lazy(() =>
    import('../modules/course-details/CourseDetails')
);

const DialACricuru = lazy(() =>
    import('../modules/dial-a-cricuru/DialACricuru')
);
const MySubscription = lazy(() =>
    import('../modules/my-subscription/MySubscription')
);
const PackDetail = lazy(() => import('../modules/pack-detail/PackDetail'));
const Partners = lazy(() => import('../modules/partners/partner'));
const RedeemSuccessPage = lazy(() =>
    import('../modules/pass/helpers/RedeemSuccessPage')
);
const Pass = lazy(() => import('../modules/pass/Pass'));
const Payment = lazy(() => import('../modules/payment/Payment'));
const Profile = lazy(() => import('../modules/profile/Profile'));
const TrialPack = lazy(() => import('../modules/trial-pack/TrialPack'));
const Watch = lazy(() => import('../modules/watch/Watch'));
const Words = lazy(() => import('../modules/words/Words'));
const ResetPassword = lazy(() =>
    import('../shared/components/auth/ResetPassword')
);
const PageNotFound404 = lazy(() =>
    import('../shared/empty-error-screens/PageNotFound404')
);

const protectedRoutes = [
    {
        path: '/home',
        component: Home,
    },
    {
        path: '/my-subscription',
        component: MySubscription,
    },
    {
        path: '/profile',
        component: Profile,
    },
    {
        path: '/payment/:productType/:slug/:productID/:paymentStatus',
        component: Payment,
    },
    {
        path: '/ai-analysis/upload',
        component: AIAnalysisUpload,
    },
    {
        path: '/watch/:slug/:courseId/:lessonId',
        component: Watch,
    },
    {
        path: '/redeem-pass/success',
        component: RedeemSuccessPage,
    },
];

const publicRoutes = [
    {
        path: '/',
        component: Home,
        authComponent: PageNotFound404,
    },
    {
        path: '/create-account',
        component: SignUp,
        authComponent: PageNotFound404,
    },
    {
        path: '/sign-in',
        component: SignIn,
        authComponent: PageNotFound404,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        authComponent: ResetPassword,
    },
    {
        path: '/course/:slug',
        component: CourseDetails,
    },
    {
        path: '/combo-packs/:slug',
        component: PackDetail,
    },
    {
        path: '/payment/:productType/:slug/:productID',
        component: Payment,
    },
    {
        path: '/ai-analysis',
        component: AIAnalysis,
    },
    {
        path: '/dial-a-cricuru',
        component: DialACricuru,
    },
    {
        path: '/trial-pack',
        component: TrialPack,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/words',
        component: Words,
    },
    {
        path: '/about',
        component: About,
    },
    {
        path: '/help-FAQs',
        component: HelpFAQ,
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-conditions',
        component: TermsConditions,
    },
    {
        path: '/contact-us',
        component: ContactUs,
    },
    {
        path: '/partners',
        component: Partners,
    },
    {
        path: '/redeem-pass',
        component: Pass,
    },
    {
        path: '/browse',
        component: Browse,
    },
    {
        path: '/all-access-detail',
        component: AllAccessDetail,
    },
];

const IndexRoute = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const token = Cookie.get('token');

    const authData = useSelector((state) => state.authCheckReducer);

    useEffect(() => {
        if (token) {
            dispatch(authCheckAction());
            dispatch(userSubscriptionAction());
        }
    }, [token]);

    useEffect(() => {
        if (location?.pathname === '/' && authData?.data?.user) {
            history.push('/home');
        }
        if (location?.pathname === '/home' && authData?.data?.error) {
            history.push('/');
        }
    }, [location, authData]);

    let isLoggedIn = false;
    if (authData?.data?.user) {
        isLoggedIn = true;
    }

    return (
        <Suspense fallback={<FallBackLoader />}>
            <Switch>
                {protectedRoutes.map((routeDetails) => (
                    <PrivateRoute
                        exact
                        path={routeDetails?.path}
                        component={routeDetails?.component}
                        isLoggedIn={isLoggedIn}
                        key={`${routeDetails?.path}`}
                    />
                ))}

                {publicRoutes.map((routeDetails) => (
                    <Route
                        exact
                        path={routeDetails?.path}
                        component={
                            isLoggedIn && routeDetails?.authComponent
                                ? routeDetails?.authComponent
                                : routeDetails?.component
                        }
                        key={`${routeDetails?.path}`}
                    />
                ))}
                <Route component={PageNotFound404} />
            </Switch>
        </Suspense>
    );
};

export default IndexRoute;
