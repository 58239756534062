import axios from '../utils/axios.instance';
import Cookie from 'js-cookie';
import { REACT_APP_API_URL } from './index.service';

const enableWatchService = () => {
    return axios
        .get(
            `${REACT_APP_API_URL}/lesson/access`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const accessLessonService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/lesson/${data.lessonId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                      withCredentials: true,
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const setWatchTimeService = ({ data }) => {
    return axios
        .put(
            `${REACT_APP_API_URL}/lesson/watchtime`,
            data,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const addBookmarkService = ({ data }) => {
    return axios
        .put(
            `${REACT_APP_API_URL}/bookmark`,
            data,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getLessonBookmarkService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/bookmark/lesson/${data.lessonId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const getCourseBookmarkService = ({ data }) => {
    return axios
        .get(
            `${REACT_APP_API_URL}/bookmark/course/${data.courseId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const deleteBookmarkService = ({ data }) => {
    return axios
        .delete(
            `${REACT_APP_API_URL}/bookmark/${data.bookmarkId}`,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

const mediaTrackingService = ({ data }) => {
    return axios
        .post(
            `${REACT_APP_API_URL}/log/playback`,
            data,
            Cookie.get('token')
                ? {
                      headers: {
                          Authorization: `Bearer ${Cookie.get('token')}`,
                      },
                  }
                : null
        )
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};

export {
    enableWatchService,
    accessLessonService,
    setWatchTimeService,
    addBookmarkService,
    getLessonBookmarkService,
    getCourseBookmarkService,
    deleteBookmarkService,
    mediaTrackingService,
};
