import { put, call } from 'redux-saga/effects';
import { couponListService } from '../services/coupan.service';

import * as types from '../action.types';

function* couponListSaga() {
    const response = yield call(couponListService);

    if (response && !response.error) {
        yield put({ type: types.COUPON_LIST_SUCCESS, response });
    } else {
        yield put({ type: types.COUPON_LIST_ERROR, response });
    }
}

export { couponListSaga };
