import * as types from '../action.types';

const initialState = {};

const getAnalysisReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_ANALYSIS_SUCCESS:
            return response;
        case types.GET_ANALYSIS_ERROR:
            return response;
        default:
            return state;
    }
};

const getAIUploadUrlReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_AI_UPLOAD_URL_SUCCESS:
            return response;
        case types.GET_AI_UPLOAD_URL_ERROR:
            return response;
        default:
            return state;
    }
};

const getAnalysisMentorReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_ANALYSIS_MENTOR_SUCCESS:
            return response;
        case types.GET_ANALYSIS_MENTOR_ERROR:
            return response;
        default:
            return state;
    }
};

const analysisPriceReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ANALYSIS_PRICES_SUCCESS:
            return response;
        case types.ANALYSIS_PRICES_ERROR:
            return response;
        default:
            return state;
    }
};

const dacNotifyReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.DAC_NOTIFY_SUCCESS:
            return response;
        case types.DAC_NOTIFY_ERROR:
            return response;
        default:
            return state;
    }
};

const clearDACNotifyReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_DAC_NOTIFY_SUCCESS:
            return response;
        case types.CLEAR_DAC_NOTIFY_ERROR:
            return response;
        default:
            return state;
    }
};

const userAIReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.USER_AI_SUCCESS:
            return response;
        case types.USER_AI_ERROR:
            return response;
        default:
            return state;
    }
};

const userDACReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.USER_DAC_SUCCESS:
            return response;
        case types.USER_DAC_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    getAnalysisReducer,
    getAIUploadUrlReducer,
    getAnalysisMentorReducer,
    analysisPriceReducer,
    dacNotifyReducer,
    clearDACNotifyReducer,
    userAIReducer,
    userDACReducer,
};
