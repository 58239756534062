import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    analysisPriceService,
    dacNotifyService,
    getAIUploadUrlService, getAnalysisMentorService,
    getAnalysisService,
    userAIService,
    userDACService
} from '../services/analysis.service';

function* getAnalysisSaga() {
    const response = yield call(getAnalysisService);

    if (response && !response.error) {
        yield put({ type: types.GET_ANALYSIS_SUCCESS, response });
    } else {
        yield put({ type: types.GET_ANALYSIS_ERROR, response });
    }
}

function* getAIUploadUrlSaga() {
    const response = yield call(getAIUploadUrlService);

    if (response && !response.error) {
        yield put({ type: types.GET_AI_UPLOAD_URL_SUCCESS, response });
    } else {
        yield put({ type: types.GET_AI_UPLOAD_URL_ERROR, response });
    }
}

function* getAnalysisMentorSaga() {
    const response = yield call(getAnalysisMentorService);

    if (response && !response.error) {
        yield put({ type: types.GET_ANALYSIS_MENTOR_SUCCESS, response });
    } else {
        yield put({ type: types.GET_ANALYSIS_MENTOR_ERROR, response });
    }
}

function* analysisPriceSaga(payload) {
    const response = yield call(analysisPriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.ANALYSIS_PRICES_SUCCESS, response });
    } else {
        yield put({ type: types.ANALYSIS_PRICES_ERROR, response });
    }
}

function* dacNotifySaga(payload) {
    const response = yield call(dacNotifyService, payload);

    if (response && !response.error) {
        yield put({ type: types.DAC_NOTIFY_SUCCESS, response });
    } else {
        yield put({ type: types.DAC_NOTIFY_ERROR, response });
    }
}

function* userAISaga() {
    const response = yield call(userAIService);

    if (response && !response.error) {
        yield put({ type: types.USER_AI_SUCCESS, response });
    } else {
        yield put({ type: types.USER_AI_ERROR, response });
    }
}

function* userDACSaga() {
    const response = yield call(userDACService);

    if (response && !response.error) {
        yield put({ type: types.USER_DAC_SUCCESS, response });
    } else {
        yield put({ type: types.USER_DAC_ERROR, response });
    }
}

export {
    getAnalysisSaga,
    getAIUploadUrlSaga,
    getAnalysisMentorSaga,
    analysisPriceSaga,
    dacNotifySaga,
    userAISaga,
    userDACSaga,
};
