import {
    getAllAccessPriceService,
    getAllAccessService,
} from '../services/all-access.service';
import * as types from '../action.types';
import { call, put } from 'redux-saga/effects';

export function* getAllAccessSaga() {
    const response = yield call(getAllAccessService);

    if (response && !response.error) {
        yield put({ type: types.ALL_ACCESS_SUCCESS, response });
    } else {
        yield put({ type: types.ALL_ACCESS_ERROR, response });
    }
}

export function* getAllAccessPriceSaga(payload) {
    const response = yield call(getAllAccessPriceService, payload);

    if (response && !response.error) {
        yield put({ type: types.ALL_ACCESS_PRICE_SUCCESS, response });
    } else {
        yield put({ type: types.ALL_ACCESS_PRICE_ERROR, response });
    }
}
