import * as types from '../action.types';

const initialState = {};

const enableWatchReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ENABLE_WATCH_SUCCESS:
            return response;
        case types.ENABLE_WATCH_ERROR:
            return response;
        default:
            return state;
    }
};

const accessLessonReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ACCESS_LESSON_SUCCESS:
            return response;
        case types.ACCESS_LESSON_ERROR:
            return response;
        default:
            return state;
    }
};

const setWatchTimeReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SET_WATCHTIME_SUCCESS:
            return response;
        case types.SET_WATCHTIME_ERROR:
            return response;
        default:
            return state;
    }
};

const addBookmarkReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.ADD_BOOKMARK_SUCCESS:
            return response;
        case types.ADD_BOOKMARK_ERROR:
            return response;
        default:
            return state;
    }
};

const getLessonBookmarkReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_LESSON_BOOKMARK_SUCCESS:
            return response;
        case types.GET_LESSON_BOOKMARK_ERROR:
            return response;
        default:
            return state;
    }
};

const getCourseBookmarkReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_COURSE_BOOKMARK_SUCCESS:
            return response;
        case types.GET_COURSE_BOOKMARK_ERROR:
            return response;
        default:
            return state;
    }
};

const deleteBookmarkReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.DELETE_BOOKMARK_SUCCESS:
            return response;
        case types.DELETE_BOOKMARK_ERROR:
            return response;
        default:
            return state;
    }
};

const mediaTrackingReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.MEDIA_TRACKING_SUCCESS:
            return response;
        case types.MEDIA_TRACKING_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    enableWatchReducer,
    accessLessonReducer,
    setWatchTimeReducer,
    addBookmarkReducer,
    getLessonBookmarkReducer,
    getCourseBookmarkReducer,
    deleteBookmarkReducer,
    mediaTrackingReducer
};
