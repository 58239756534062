import * as types from '../action.types';

const initialState = {
    isLoggedIn: false,
};

const signupReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SIGNUP_SUCCESS:
            return response;
        case types.SIGNUP_ERROR:
            return response;
        default:
            return state;
    }
};

const signinReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SIGNIN_SUCCESS:
            return response;
        case types.SIGNIN_ERROR:
            return response;
        default:
            return state;
    }
};

const authCheckReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.AUTHCHECK_SUCCESS:
            return response;
        case types.AUTHCHECK_ERROR:
            return response;
        default:
            return state;
    }
};

const signoutReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SIGNOUT_SUCCESS:
            return response;
        case types.SIGNOUT_ERROR:
            return response;
        default:
            return state;
    }
};

const forgotPasswordReducer = (state = {}, action) => {
    const response = action.response;

    switch (action.type) {
        case types.FORGOT_PASSWORD_SUCCESS:
            return {
                success: true,
                message: response.message,
            };
        case types.FORGOT_PASSWORD_ERROR:
            return response;
        default:
            return state;
    }
};

const resetPasswordReducer = (state = {}, action) => {
    const response = action.response;

    switch (action.type) {
        case types.RESET_PASSWORD_SUCCESS:
            return {
                success: true,
                message: response.message,
            };
        case types.RESET_PASSWORD_ERROR:
            return response;
        default:
            return state;
    }
};

const socialAccountLinkReducer = (state = {}, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SOCIAL_ACCOUNT_LINK_SUCCESS:
            return response;
        case types.SOCIAL_ACCOUNT_LINK_ERROR:
            return response;
        default:
            return state;
    }
};

const socialAccountUnLinkReducer = (state = {}, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SOCIAL_ACCOUNT_UNLINK_SUCCESS:
            return response;
        case types.SOCIAL_ACCOUNT_UNLINK_ERROR:
            return response;
        default:
            return state;
    }
};

const verifySignupReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.VERIFY_SIGNUP_SUCCESS:
            return response;
        case types.VERIFY_SIGNUP_ERROR:
            return response;
        default:
            return state;
    }
};

const sendOTPReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SEND_OTP_SUCCESS:
            return response;
        case types.SEND_OTP_ERROR:
            return response;
        default:
            return state;
    }
};

const clearChangePassReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CHANGE_PASS_CLEANUP_SUCCESS:
            return response;
        case types.CHANGE_PASS_CLEANUP_ERROR:
            return response;
        default:
            return state;
    }
};

const signInOTPReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SIGNIN_OTP_SUCCESS:
            return response;
        case types.SIGNIN_OTP_ERROR:
            return response;
        default:
            return state;
    }
};

const signUpOTPReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.SIGNUP_OTP_SUCCESS:
            return response;
        case types.SIGNUP_OTP_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    signupReducer,
    signinReducer,
    authCheckReducer,
    signoutReducer,
    forgotPasswordReducer,
    resetPasswordReducer,
    socialAccountLinkReducer,
    socialAccountUnLinkReducer,
    verifySignupReducer,
    sendOTPReducer,
    clearChangePassReducer,
    signInOTPReducer,
    signUpOTPReducer,
};
