import { call, put } from 'redux-saga/effects';
import * as types from '../action.types';
import {
    changeEmailService,
    changePasswordService,
    editProfileService,
    generatePasswordService,
    userPurchaseService,
    userSubscriptionService,
} from '../services/profile.service';

function* changePasswordSaga(payload) {
    const response = yield call(changePasswordService, payload);

    if (response && !response.error) {
        yield put({ type: types.CHANGE_PASSWORD_SUCCESS, response });
    } else {
        yield put({ type: types.CHANGE_PASSWORD_ERROR, response });
    }
}

function* generatePasswordSaga(payload) {
    const response = yield call(generatePasswordService, payload);

    if (response && !response.error) {
        yield put({ type: types.GENERATE_PASSWORD_SUCCESS, response });
    } else {
        yield put({ type: types.GENERATE_PASSWORD_ERROR, response });
    }
}

function* changeEmailSaga(payload) {
    const response = yield call(changeEmailService, payload);

    if (response && !response.error) {
        yield put({ type: types.CHANGE_EMAIL_SUCCESS, response });
    } else {
        yield put({ type: types.CHANGE_EMAIL_ERROR, response });
    }
}

function* userPurchaseSaga() {
    const response = yield call(userPurchaseService);

    if (response && !response.error) {
        yield put({ type: types.USER_PURCHASE_SUCCESS, response });
    } else {
        yield put({ type: types.USER_PURCHASE_ERROR, response });
    }
}

function* userSubscriptionSaga() {
    const response = yield call(userSubscriptionService);

    if (response && !response.error) {
        yield put({ type: types.USER_SUBSCRIPTION_SUCCESS, response });
    } else {
        yield put({ type: types.USER_SUBSCRIPTION_ERROR, response });
    }
}

function* editProfileSaga(payload) {
    const response = yield call(editProfileService, payload);

    if (response && !response.error) {
        yield put({ type: types.EDIT_PROFILE_SUCCESS, response });
        window.location.reload();
    } else {
        yield put({ type: types.EDIT_PROFILE_ERROR, response });
    }
}

export {
    changePasswordSaga,
    generatePasswordSaga,
    changeEmailSaga,
    userPurchaseSaga,
    userSubscriptionSaga,
    editProfileSaga,
};
