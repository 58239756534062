import * as types from '../action.types';

const initialState = {};

const verifyPassReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.VERIFY_PASS_SUCCESS:
            return response;
        case types.VERIFY_PASS_ERROR:
            return response;
        default:
            return state;
    }
};

const redeemPassReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.REDEEM_PASS_SUCCESS:
            return response;
        case types.REDEEM_PASS_ERROR:
            return response;
        default:
            return state;
    }
};

const getRedeemPassListReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.GET_REDEEM_PASS_LIST_SUCCESS:
            return response;
        case types.GET_REDEEM_PASS_LIST_ERROR:
            return response;
        default:
            return state;
    }
};

const clearVerifyPassReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_VERIFY_PASS_SUCCESS:
            return response;
        case types.CLEAR_VERIFY_PASS_ERROR:
            return response;
        default:
            return state;
    }
};

const clearRedeemPassReducer = (state = initialState, action) => {
    const response = action.response;

    switch (action.type) {
        case types.CLEAR_REDEEM_PASS_SUCCESS:
            return response;
        case types.CLEAR_REDEEM_PASS_ERROR:
            return response;
        default:
            return state;
    }
};

export {
    verifyPassReducer,
    redeemPassReducer,
    getRedeemPassListReducer,
    clearVerifyPassReducer,
    clearRedeemPassReducer,
};
